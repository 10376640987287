<template>
  <div id="app" style="">
    <!--  路由占位符  -->
    <router-view></router-view>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'app'
  // components: {
  //  HelloWorld
  // }
}
</script>

<style lang="scss">
/*@import "./assets/scss/vendors/bootstrap-vue/index.scss";*/
/*@import "./assets/scss/vendors/bootstrap-vue/index.scss";*/
/*#app {*/
/*  font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/

body {
  margin: 0;
  padding: 0;
  border: 0;
}
</style>
