<template>
  <div id="Cate">
    <!--  面包屑导航按钮  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品分类</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  Card 卡片视图区域   -->
    <el-card>
      <!-- 添加按钮 -->
      <el-row>
        <el-col>
          <el-button type="primary" @click="showAddCateDialog">添加分类</el-button>
        </el-col>
      </el-row>

      <!--表格-->
      <tree-table
        :columns="columns"
        :data="cateList"
        :expand-type="false"
        :selection-type="false"
        :show-row-hover="false"
        border
        class="TreeTable"
        element-loading-text="拼命加载中"
        index-text="#"
        show-index
        v-loading="loading">
        <!--是否有效-->
        <template slot="isOk" slot-scope="scope">
          <i class="el-icon-circle-check" style="color: limegreen" v-if="scope.row.cat_deleted === false"></i>
          <i class="el-icon-circle-close" style="color: red" v-else></i>
        </template>
        <!--排序-->
        <template slot="order" slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.cat_level === 0">一级</el-tag>
          <el-tag size="mini" type="success" v-else-if="scope.row.cat_level === 1">二级</el-tag>
          <el-tag size="mini" type="warning" v-else>三级</el-tag>
        </template>
        <!--操作-->
        <template slot="opt" slot-scope="scope">
          <el-button @click="editCate(scope.row)" icon="el-icon-edit" size="mini" type="primary">编辑</el-button>
          <el-button @click="removeCate(scope.row)" icon="el-icon-delete" size="mini" type="danger">删除</el-button>
        </template>
      </tree-table>
      <!--分页-->
      <el-pagination
        :current-page="querInfo.pagenum"
        :page-size="querInfo.pagesize"
        :page-sizes="pageNumSize"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </el-card>
    <!--编辑el-dialog对话框-->
    <el-dialog
      :visible.sync="cateDialogVisible"
      title="修改分类"
      width="50%">
      <el-form :model="editCateName" :rules="cateNameRules" label-width="100px" ref="editCateRef">
        <el-form-item label="分类名称：" prop="cat_name">
          <el-input v-model="editCateName.cat_name"></el-input>
        </el-form-item>
      </el-form>
      <span class="dialog-footer" slot="footer">
    <el-button @click="cateDialogVisible = false">取 消</el-button>
    <el-button @click="saveCateInfo" type="primary">确 定</el-button>
    </span>
    </el-dialog>

    <!-- 添加分类对话框-->
    <el-dialog
      title="添加分类"
      :visible.sync="addCateDialogVisible"
      width="50%"
      @close="editCateClose"
      >
      <el-form   :model="editCateName" :rules="cateNameRules"  ref="addCateRef" label-width="100px" >
        <el-form-item label="分类名称" prop="cat_name" >
          <el-input v-model="editCateName.cat_name"></el-input>
        </el-form-item>
        <el-form-item label="父级分类"  >
          <!--及联选择器-->
          <!-- :options 指定数据源-->
          <!-- :props 用来指定配置对象-->
          <el-cascader
            v-model="selectedKeys"
            :options="parentCateList"
            :props="cascaderProps"
            clearable
            @change="parentCateChanged"
            popper-class="cascaderPopper"></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="addCateDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addCate">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Cate',
  data () {
    return {
      // 查询条件参数
      querInfo: {
        type: 3,
        pagenum: 1,
        pagesize: 10
      },
      // 商品分类的数据列表
      cateList: [],
      // 为总数据条数赋值
      total: 0,
      // 表格各列的配置
      columns: [
        {
          label: '分类名称',
          prop: 'cat_name'
        },
        {
          label: '是否有效',
          prop: 'cat_deleted',
          type: 'template',
          template: 'isOk'
        },
        {
          // lable  列的标题
          label: '排序',
          // 对应的参数值
          prop: 'cat_level',
          // 表示类型定义为模版列
          type: 'template',
          // 模版名称
          template: 'order'
        },
        {
          // lable  列的标题
          label: '操作',
          // 对应的参数值
          prop: '',
          // 表示类型定义为模版列
          type: 'template',
          // 模版名称
          template: 'opt'
        }
      ],
      // 监听编辑按钮的事件开关
      cateDialogVisible: false,
      // 监听添加分类按钮的事件开关
      addCateDialogVisible: false,
      // 编辑添加分类名称
      editCateName: {
        cat_name: '',
        // 父级分类id 默认0
        cat_pid: 0,
        // 分类等级，默认添加一级分类
        cat_level: 0
      },
      // 添加分类表单的规则验证
      cateNameRules: {
        cat_name: [{
          required: true,
          message: '请输入分类名',
          trigger: 'blur'
        },
        {
          min: 1,
          max: 32,
          message: '长度在 1 到 32 个字符间',
          trigger: 'blur'
        }]
      },
      loading: true,
      removeCateId: '',
      // 父级分类的列表
      parentCateList: [],
      //  用来指定级联选择器的数据对象
      cascaderProps: {
        expandTrigger: 'hover',
        value: 'cat_id',
        label: 'cat_name',
        children: 'children',
        checkStrictly: true
      },
      // 选中的父级分类的id数组
      selectedKeys: [],
      // 每页显示个数选择器的选项设置
      pageNumSize: [5, 10, 20, 50]
    }
  },
  created () {
    this.getCateList()
  },
  methods: {
    // 获取所有商品分类数据
    async getCateList () {
      const pageNum = window.sessionStorage.getItem('pagenum')
      if (pageNum) {
        this.querInfo.pagenum = window.sessionStorage.getItem('pagenum') * 1
        this.querInfo.pagesize = window.sessionStorage.getItem('pagesize') * 1
        const { data: res } = await this.$http.get('categories', { params: this.querInfo })
        if (res.meta.status !== 200) return this.$message.error('获取商品数据失败' + res.meta.msg)
        this.cateList = res.data.result
        this.total = res.data.total
        this.loading = false
        // console.log(this.pageNumSize)
      } else {
        const { data: res } = await this.$http.get('categories', { params: this.querInfo })
        if (res.meta.status !== 200) return this.$message.error('获取商品数据失败' + res.meta.msg)
        // console.log(res.data.result)
        this.cateList = res.data.result
        this.total = res.data.total
        this.loading = false
        // console.log(this.pageNumSize)
      }
    },
    // 监听 pagesize 每页显示多少条数据 改变的事件函数
    handleSizeChange (NewSize) {
      // console.log(`每页 ${NewSize} 条`);
      window.sessionStorage.setItem('pagesize', NewSize)
      window.sessionStorage.setItem('pagenum', this.querInfo.pagenum)
      this.querInfo.pagesize = NewSize
      this.loading = true
      this.getCateList()
    },
    // 监听 页码值 改变的事件函数
    handleCurrentChange (NewPage) {
      // console.log(`当前页: ${NewPage}`);
      window.sessionStorage.setItem('pagenum', NewPage)
      window.sessionStorage.setItem('pagesize', this.querInfo.pagesize)
      this.querInfo.pagenum = NewPage
      this.loading = true
      this.getCateList()
    },
    // 编辑弹出框操作修改数据
    editCate (cateInfo) {
      this.editCateName.cat_name = cateInfo.cat_name
      this.editCateName.cat_id = cateInfo.cat_id
      this.cateDialogVisible = true
    },
    saveCateInfo () {
      this.$refs.editCateRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return
        // 通过预校验 ，发起添加用户网络请求
        const { data: res } = await this.$http.put(`categories/${this.editCateName.cat_id}`, {
          cat_name: this.editCateName.cat_name
        })
        if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
        this.$message.success(res.meta.msg)
        // 重新获取用户列表
        this.getCateList()
        // 关闭对话框
        this.cateDialogVisible = false
      })
    },
    removeCate (cateInfo) {
      // console.log(cateInfo)
      this.removeCateId = cateInfo.cat_id
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.delete(`categories/${this.removeCateId}`)
        if (res.meta.status !== 200) return this.$message.error('删除失败！！' + res.meta.msg)
        cateInfo = res.data

        this.$message.success({
          message: '删除成功!'
        })
        // 在删除事件里面，删除成功加入这段代码 vue 分页删除最后一条数据，返回上一级的分页
        // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
        const totalPage = Math.ceil((this.total - 1) / this.querInfo.pagesize) // 设置总页数
        this.querInfo.pagenum = this.querInfo.pagenum > totalPage ? totalPage : this.querInfo.pagenum
        this.querInfo.pagenum = this.querInfo.pagenum < 1 ? 1 : this.querInfo.pagenum
        window.sessionStorage.setItem('pagenum', this.querInfo.pagenum)
        // 重新获取列表
        await this.getCateList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    showAddCateDialog () {
      // 先获取父级分类的数据列表
      this.getParentCateList()
      // 再弹出添加分类对话框
      this.addCateDialogVisible = true
    },
    // 获取父级分类的数据列表
    async getParentCateList () {
      const { data: res } = await this.$http.get('categories', { params: { type: 2 } })
      if (res.meta.status !== 200) return this.$message.error('获取父级分类失败! ' + res.meta.msg)
      this.parentCateList = res.data
      // console.log(res.data)
    },
    // 选择项发生变化触发这个函数
    async parentCateChanged () {
      // console.log(this.selectedKeys)
      // 如果selectKeys数组中的 length 大于0 证明选中了父级分类
      if (this.selectedKeys.length > 0) {
        // 父级分类的id
        this.editCateName.cat_pid = this.selectedKeys[this.selectedKeys.length - 1]
        // 为当前分类的等级赋值
        this.editCateName.cat_level = this.selectedKeys.length
      } else {
        // 父级分类的id
        this.editCateName.cat_pid = 0
        // 为当前分类的等级赋值
        this.editCateName.cat_level = 0
      }
    },
    async addCate () {
      // console.log(this.editCateName)
      this.$refs.addCateRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('categories', this.editCateName)
        if (res.meta.status !== 201) return this.$message.error('添加分类失败! ' + res.meta.msg)
        this.$message.success('添加分类成功')
        this.getCateList()
        this.addCateDialogVisible = false
      })
    },
    editCateClose () {
      this.$refs.addCateRef.resetFields()
      this.editCateName.cat_pid = 0
      this.editCateName.cat_level = 0
      this.selectedKeys = []
    }
  }
}
</script>

<style lang="less" scoped>
  .TreeTable {
    margin-top: 20px;
  }
  .el-cascader {
    width: 100%;
  }
</style>
