import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
// import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import 'jquery/dist/jquery.min.js'
// 导入全局样式
import './assets/css/global.css'
import TreeTable from 'vue-table-with-tree-grid'
import '../public/static/style.css'
import  '@/assets/css/font.css'
// 导入http请求的 axios

import axios from 'axios'

Vue.config.productionTip = false

// 配置请求的根路径
axios.defaults.baseURL = 'https://api-service.xiedeqingxdq.com/api/private/v1/'
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})



// 配置可以支持this.$http
Vue.prototype.$http = axios
Vue.component('tree-table', TreeTable)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
