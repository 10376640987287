<template>
  <div>
    <!--面包屑导航栏-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- Card 卡片视图区域-->
    <el-card class="box-card">
      <el-row>
        <el-button @click="dialogVisible = true" type="primary">添加角色</el-button>
      </el-row>
      <el-table
        :data="rolesList"
        border
        style="width: 100%">
        <!--展开列-->
        <el-table-column
          label="展开"
          type="expand">
          <template slot-scope="scope">
            <el-row :class="['bdbottom', i1 === 0  ? 'bdtop' : '' ,'vcenter']" :key="item1.id"
                    class="vcenter" v-for="(item1, i1) in scope.row.children">
              <!--渲染一级权限-->
              <el-col :span="4">
                <el-tag @close="removerRightById(scope.row,item1.id)" closable>{{item1.authName}}</el-tag>
                <i class="el-icon-caret-right"></i>

              </el-col>
              <!--渲染二级和三级权限-->
              <el-col :span="19">
                <!-- for 循环渲染二级权限-->
                <el-row :class="[ i2 === 0 ? '' : 'bdtop', 'vcenter']" :key="item2.id"
                        v-for="(item2, i2) in item1.children">
                  <el-col :span="6">
                    <el-tag @close="removerRightById(scope.row,item2.id)" closable type="success">{{item2.authName}}
                    </el-tag>
                    <i class="el-icon-caret-right"></i></el-col>

                  <el-col :span="18">
                    <el-tag :key="item3.id" @close="removerRightById(scope.row,item3.id)" closable
                            v-for="(item3, i3) in item2.children">{{item3.authName}}
                    </el-tag>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <!-- 索引列 -->
        <el-table-column
          label="#"
          type="index">
        </el-table-column>
        <el-table-column
          label="角色名称"
          prop="roleName"
        >
        </el-table-column>
        <el-table-column
          label="角色描述"
          prop="roleDesc"
        >
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button @click="editRoles(scope.row.id)" icon="el-icon-edit" size="mini" type="primary">编辑</el-button>
            <el-button @click="removeRoles(scope.row.id)" icon="el-icon-delete" size="mini" type="warning">删除
            </el-button>
            <el-button @click="showSetRightDialog(scope.row)" icon="el-icon-search" size="small" type="danger">分配权限
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加角色弹出框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      @close="addHandleClose"
      title="添加角色"
      width="40%">
      <el-form :model="addRole" :rules="rolesRules" label-width="100px" ref="rolesFormRef">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="addRole.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="roleDesc">
          <el-input v-model="addRole.roleDesc"></el-input>
        </el-form-item>
      </el-form>
      <span class="dialog-footer" slot="footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button @click="addRoles" type="primary">确 定</el-button>
        </span>
    </el-dialog>
    <!--编辑角色弹出框-->
    <el-dialog
      :visible.sync="editRolesDialogVisible"
      @close="editHandleClose"
      title="修改角色"
      width="40%">
      <el-form :model="editRole" :rules="rolesRules" label-width="100px" ref="editRolesFormRef">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="editRole.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input v-model="editRole.roleDesc"></el-input>
        </el-form-item>
      </el-form>
      <span class="dialog-footer" slot="footer">
          <el-button @click="editRolesDialogVisible = false">取 消</el-button>
    <el-button @click="upDataEditRoles" type="primary">确 定</el-button>
        </span>
    </el-dialog>
    <!--分配权限对话框-->
    <el-dialog
      :visible.sync="setRightDialogVisible"
      @close="setRightDialogClosed"
      title="分配权限"
      width="50%"
    >
      <!-- 树形控件-->
      <el-tree :data="rightsList" :default-checked-keys="defKeys" :props="TreeProps" :default-expand-all="true" node-key="id"
               show-checkbox ref="treeRef"></el-tree>
      <span class="dialog-footer" slot="footer">
        <el-button @click="setRightDialogVisible = false">取 消</el-button>
        <el-button @click="allotRights" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      rolesList: [],
      rightsList: [],
      // 添加角色的对话框开关
      dialogVisible: false,
      // 修改用户权限的对话框开关
      editRolesDialogVisible: false,
      // 控制分配权限的对话框的开关
      setRightDialogVisible: false,
      // 默认勾选的节点id值
      defKeys: [],
      // 当前即将分配权限的角色id
      roleIds: '',
      // 树形控件的属性绑定对象
      TreeProps: {
        children: 'children',
        label: 'authName'
      },
      addRole: {
        roleName: '',
        roleDesc: ''
      },
      editRole: {
        id: '',
        roleName: '',
        roleDesc: ''
      },
      rolesRules: {
        roleName: [{
          required: true,
          message: '请输入角色名',
          trigger: 'blur'
        },
        {
          min: 2,
          max: 16,
          message: '长度在 2 到 32 个字符间',
          trigger: 'blur'
        }],
        roleDesc: [{
          required: true,
          message: '请输入描述信息',
          trigger: 'blur'
        },
        {
          min: 2,
          max: 128,
          message: '长度在 2 到 128 个字符间',
          trigger: 'blur'
        }]

      }
    }
  },
  created () {
    this.getRolesList()
  },
  methods: {
    // 获取角色列表函数
    async getRolesList () {
      const { data: res } = await this.$http.get('/roles')
      // console.log(res)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.rolesList = res.data
      // this.$message.success('获取菜单列表成功')
    },
    addRoles () {
      this.$refs.rolesFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.post('roles', this.addRole)
        if (res.meta.status !== 201) return this.$message.error(res.meta.msg)
        this.$message.success(res.meta.msg)
        this.dialogVisible = false
        await this.getRolesList()
      })
    },
    // 清理添加用户对话框的错误信息
    addHandleClose () {
      this.$refs.rolesFormRef.resetFields()
      this.addRole.roleName = ''
      this.addRole.roleDesc = ''
      this.dialogVisible = false
    },
    async editRoles (editInfo) {
      this.editRolesDialogVisible = true
      const { data: res } = await this.$http.get(`/roles/${editInfo}`)
      // console.log(res)
      this.editRole.roleName = res.data.roleName
      this.editRole.roleDesc = res.data.roleDesc
      this.editRole.id = editInfo
    },
    // 清理关闭对话框的错误信息
    editHandleClose () {
      this.$refs.editRolesFormRef.resetFields()
      this.editRole.roleName = ''
      this.editRole.roleDesc = ''
      this.editRolesDialogVisible = false
    },
    // 更新角色信息
    upDataEditRoles () {
      this.$refs.editRolesFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put(`roles/${this.editRole.id}`, {
          roleName: this.editRole.roleName,
          roleDesc: this.editRole.roleDesc
        })
        if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
        this.$message.success('修改用户信息成功')
        this.editRolesDialogVisible = false
        await this.getRolesList()
      })
    },
    // 删除角色
    removeRoles (id) {
      // console.log(id)
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.delete(`roles/${id}`)
        if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
        this.$message.success(res.meta.msg)
        await this.getRolesList()
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    async removerRightById (roleId, rightId) {
      // 弹框提示用户是否删除
      const confirmResult = await this.$confirm('此操作将永久删除该权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('用户取消删除')
      }
      const { data: res } = await this.$http.delete(`roles/${roleId.id}/rights/${rightId}`)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      // await this.getRolesList()
      // 删除成功后重新赋值当前角色的权限信息，不需要重新获取所有表格
      roleId.children = res.data
      this.$message.success('删除用户信息成功')
    },
    // 展示分配权限的对话框
    async showSetRightDialog (role) {
      this.roleIds = role.id
      // 获取所有权限的数据
      const { data: res } = await this.$http.get('rights/tree')
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      // 把获取到的权限数据保存到data中
      this.rightsList = res.data
      this.roleId = res.id
      // 递归获取三级节点的id值
      this.getLeafKeys(role, this.defKeys)
      this.setRightDialogVisible = true
    },
    // 通过递归的形式，获取角色下所有三级权限的id，并保存到 defKeys 数组中
    getLeafKeys (node, arr) {
      if (!node.children) {
        return arr.push(node.id)
      }
      node.children.forEach(item => this.getLeafKeys(item, arr))
    },
    // 监听分配权限对话框的关闭事件
    setRightDialogClosed () {
      this.defKeys = []
    },
    async allotRights () {
      const keys = [...this.$refs.treeRef.getCheckedKeys(), ...this.$refs.treeRef.getHalfCheckedKeys()]
      const intKeys = keys.join(',')
      const { data: res } = await this.$http.post(`roles/${this.roleIds}/rights`, { rids: intKeys })
      console.log(res)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.$message.success('权限分配成功')
      await this.getRolesList()
      this.setRightDialogVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
  .el-tag {
    margin: 7px;
  }

  /*边框*/
  .bdtop {
    border-top: 1px solid #eeeeee;
  }

  /*头部边框*/
  .bdbottom {
    border-bottom: 1px solid #eeeeee;
  }

  /*align-items: center 纵向居中对齐 */
  .vcenter {
    display: flex;
    align-items: center;
  }
</style>
