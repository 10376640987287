<template>
    <div>
      <!--  面包屑导航按钮  -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>参数列表</el-breadcrumb-item>
      </el-breadcrumb>
      <!--  Card 卡片视图区域   -->
      <el-card>
        <!-- 头部警告区域 -->
        <el-alert
          title="注意: 只允许为第三级分类设置相关参数!!!"
          type="warning"
          show-icon
          :closable="false">
        </el-alert>
        <!-- 选择商品分类区域-->
        <el-row class="cat_opt">
         <el-col>
           <span>选择商品分类：</span>
          <!--商品级联选择器-->
           <!-- v-model 双向绑定的父级分类的id数组-->
           <!-- :options 指定数据源-->
           <!-- :props 用来指定配置对象-->
           <el-cascader
             v-model="selectedKeys"
             :options="cateList"
             :props="paramsCascaderProps"

             @change="parentParamsChanged"
             popper-class="cascaderPopper"></el-cascader>
         </el-col>
        </el-row>
        <!-- tabs 页签-->
        <el-tabs  v-model="activeName" @tab-click="handleTabClick">
        <!--添加动态参数的面板-->
          <el-tab-pane label="动态参数" name="many">
            <el-button type="primary" size="mini" :disabled="isBtnDisabled" @click="addDialogVisible = true">添加参数</el-button>
            <!-- 动态table 表格区域-->
            <el-table
              :data="manyTableData"
              border
              stripe
              style="width: 100%">
              <!--  展开行-->
              <el-table-column
                label=" "
                type="expand">
                <template slot-scope="scope">
                  <el-row>
                    <el-col>
                      <el-tag >{{scope.row.attr_vals}}</el-tag>

                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column
                type="index"
                label="#">
              </el-table-column>
              <el-table-column
                prop="attr_name"
                label="参数名称">
              </el-table-column>
              <el-table-column
                label="操作">
                <template slot-scope="scope">
                  <el-button @click="editRoles(scope.row.id)" icon="el-icon-edit" size="mini" type="primary">编辑</el-button>
                  <el-button @click="removeRoles(scope.row.id)" icon="el-icon-delete" size="mini" type="danger">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        <!--添加静态属性的面板-->
          <el-tab-pane label="静态属性" name="only">
            <el-button type="primary" size="mini" :disabled="isBtnDisabled" @click="addDialogVisible = true">添加属性</el-button>
            <!-- 静态table 表格区域-->
            <el-table
              :data="onlyTableData"
              border
              stripe
              style="width: 100%">
              <!--  展开行-->
              <el-table-column
                label=" "
                type="expand">
                <template slot-scope="scope">

                </template>
              </el-table-column>
              <el-table-column
                type="index"
                label="#">
              </el-table-column>
              <el-table-column
                prop="attr_name"
                label="属性名称">
              </el-table-column>
              <el-table-column
                prop="name"
                label="操作">
                <template slot-scope="scope">
                  <el-button @click="editParams(scope.row.id)" icon="el-icon-edit" size="mini" type="primary">编辑</el-button>
                  <el-button @click="removeParams(scope.row.id)" icon="el-icon-delete" size="mini" type="danger">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <!-- 添加动态参数和静态属性对话框-->
        <el-dialog
          title="提示"
          :visible.sync="addDialogVisible"
          width="30%">
          <span>这是一段信息</span>
          <span slot="footer" class="dialog-footer">
    <el-button @click="addDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addDialogVisible = false">确 定</el-button>
  </span>
        </el-dialog>

      </el-card>
    </div>
</template>

<script>
export default {
  name: 'Params',
  data () {
    return {
      // 商品分类数据列表
      cateList: [],
      // 用来指定级联选择器的数据对象，指定列表数据源配置对象
      paramsCascaderProps: {
        expandTrigger: 'hover',
        value: 'cat_id',
        label: 'cat_name',
        children: 'children'
      },
      // 双向绑定的父级分类的id数组
      selectedKeys: [],
      // 被激活的页签名称
      activeName: 'many',
      // 动态参数列表
      manyTableData: [],
      // 静态属性列表
      onlyTableData: [],
      // 控制对话框的显示与隐藏
      addDialogVisible: false
    }
  },
  created () {
    this.getCateList()
  },
  methods: {
    async getCateList () {
      const { data: res } = await this.$http.get('categories')
      if (res.meta.status !== 200) return this.$message.error('获取商品列表失败' + res.meta.msg)
      this.cateList = res.data
    },
    // 级联选择框选中项变化触发时的处理函数事件
    parentParamsChanged () {
      if (this.selectedKeys.length !== 3) {
        this.selectedKeys = []
        return this.$message.warning('请选择三级分类')
      }
      // 根据所选分类的id，和当前所处的面板，获取对应的参数列表
      this.getParamsListData()
      // console.log(this.selectedKeys[2],this.selectedKeys)
    },
    // 页签点击事件的触发函数
    handleTabClick () {
      if (this.selectedKeys.length !== 3) {
        this.selectedKeys = []
        return this.$message.warning('请选择商品分类')
      }
      this.getParamsListData()
    },
    // 获取所有参数列表
    async getParamsListData () {
      const { data: res } = await this.$http.get(`categories/${this.selectedKeys[2]}/attributes`, { params: { sel: this.activeName } })
      if (res.meta.status !== 200) return this.$message.error('获取参数列表失败！！ ' + res.meta.msg)
      if (this.activeName === 'many') {
        this.manyTableData = res.data
      } else {
        this.onlyTableData = res.data
      }
      console.log(res.data)
    }
  },
  // computed 函数的方式 动态获取data数据。调用时直接用 this.函数名
  computed: {
    // 如果按钮需要被禁用，则返回true，否则返回false
    isBtnDisabled () {
      if (this.selectedKeys.length !== 3) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="less" scoped>
  .cat_opt {
    margin-top: 20px;
  }
  .el-tabs {
    margin-top: 20px;
  }
</style>
