<template>
    <div>数据报表</div>
</template>

<script>
export default {
  name: 'Reports'
}
</script>

<style lang="less" scoped>

</style>
