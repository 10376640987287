<template>
  <div>
    <!--面包屑导航栏-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- Card 卡片视图区域 -->
    <el-card class="box-card">
      <el-table
        :data="RightsList"
        border
        style="width: 100%">
        <el-table-column
            label="#"
            type="index">
          </el-table-column>
          <el-table-column
            label="权限名称"
            prop="authName">
          </el-table-column>
          <el-table-column
            label="路径"
            prop="path">
          </el-table-column>
          <el-table-column
            label="权限等级">
            <template slot-scope="scope">
              <el-tag size="mini" v-if="scope.row.level === '0'">一级</el-tag>
              <el-tag size="mini" type="success"  v-else-if="scope.row.level === '1'">二级</el-tag>
              <el-tag size="mini" type="warning"  v-else="scope.row.level === '2'">三级</el-tag>
            </template>
          </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Rights',
  data () {
    return {
      // 权限列表所有数据
      RightsList: [],
      // 获取权限列表的请求参数
      RightsListType: 'list'
    }
  },
  created () {
    // 提前获取权限列表函数
    this.getRightsList()
  },
  methods: {
    // 获取权限列表函数
    async getRightsList () {
      const { data: res } = await this.$http.get(`rights/${this.RightsListType}`)
      // console.log(res.data)
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.RightsList = res.data
    }
  }
}
</script>

<style lang="less" scoped>

</style>
