<template>
  <div class="login_container">
    <div class="login_box" >
      <!--   头像区域   -->
      <div class="avatar_box">
        <img src="~@/assets/helihong(1).jpg">
      </div>
      <!--   登录表单区域   -->
      <el-form :model="loginFrom" :rules="loginFromRules" class="login_from" label-width="0px" ref="loginFromRef"
               size="mini">
        <el-form-item prop="username">
          <!--  用户名  -->
          <el-input  placeholder="用户名" prefix-icon="el-icon-user-solid" v-model="loginFrom.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <!-- 密码  @keyup.enter.native="dianJiFunc" 按回车键自动登录-->
          <el-input placeholder="密码" prefix-icon="el-icon-lock" type="password" v-model="loginFrom.password"></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button @click="dianJiFunc" type="primary">登录</el-button>
          <el-button disabled @click="resetForm('loginFromRef')" type="info">退出</el-button>

        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      title="我喜欢你很久了"
      :visible.sync="dialogVisible"
      width="42%"
      center
      modal
      :show-close="false"
      :before-close="handleClose">
      <div class="div1" >♥贺贺做我对象好不好♥</div>
      <div class="div2" >I Like You</div>
      <span slot="footer" class="dialog-footer">
    <el-button size="mini" class="button1" @click="buHao">不 好</el-button>
    <el-button size="mini" class="button1" type="primary" @click="login('loginFromRef')">好</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loginFrom: {
        username: 'helihong',
        password: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      userPassword: {
        username: 'admin',
        password: '123456'
      },
      // 表单的验证规则对象
      loginFromRules: {
        // 验证用户名是否合法
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          },
          {
            min: 4,
            max: 12,
            message: '长度在 4 到 10 个字符间',
            trigger: 'blur'
          }
        ],
        // 验证密码是否合法
        password: [
          {
            required: true,
            message: '小贺贺，请输入密码呦',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 16,
            message: '小贺贺，密码是6个数字呦',
            trigger: 'blur'
          }

        ]
      },
      dialogVisible: false,
      dianJi: 0,
    }
  },
  mounted () {
    window.addEventListener('keyup' ,this.onSubmit,false)
  },
  destroyed () {
    window.removeEventListener('keyup',this.onSubmit,false)
  },
  methods: {
    onSubmit (e) {
      if (e.keyCode == 13) {
        this.dianJiFunc();
      }
    },
    login () {
      this.$refs.loginFromRef.validate( valid => {
        // console.log(valid);
        if (!valid) return
        // const { data: res } =  this.$http.post('login', this.loginFrom)
        // console.log(res)
        // if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
        // this.$message.success('登录成功')
        // // 登录成功后的用户token，保存到sessionStorage中。
        window.sessionStorage.setItem('token', "helihong5201314")

        alert("贺贺你终于同意了,么么哒。等了好久终于等到今天。    我们的网站是https//helihong.xiedeqingxdq.com")
        // 成功后通过编程式导航跳转到后台主页，路由地址是 /home
        this.$router.push('/home')
        // this.$message({
        //   type: 'success',
        //   center: true,
        //   offset: 50,
        //   message: '贺贺你终于同意了,么么哒。等了好久终于等到今天!!!    我们的网站是:https//www.xiedeqingxdq.com',
        //   duration: 3000
        // })

        // location.assign("https://helihong.xiedeqingxdq.com/tree")
      })
    },
    resetForm (formName) {
      // console.log(this);
      this.$refs[formName].resetFields()
    },
    handleClose () {},
    dianJiFunc () {
      this.$refs.loginFromRef.validate(async valid => {
      if (this.loginFrom.username !== 'helihong') return this.$message.error('对不起，通往爱情大门的用户不对，无法打开大门，请更换用户名为helihong再试')
      if (this.loginFrom.password !== '5201314') return this.$message.error('对不起，通往爱情大门的钥匙不对，无法打开大门，请换把钥匙再试')
        this.loginFrom.username = 'admin'
        this.loginFrom.password = '123456'
        this.dialogVisible = true
      })
    },
    buHao() {
      this.dianJi += 1;
      if (this.dianJi === 1) return this.$message({
        type: 'warning',
        center: true,
        offset: 50,
        message: '小贺贺，再考虑一下呗',
        duration: 5000
      })
      if (this.dianJi === 2) return this.$message({
        type: 'warning',
        center: true,
        offset: 50,
        message: '家务我全干',
        duration: 5000
      })
      if (this.dianJi === 3) return this.$message({
        type: 'warning',
        center: true,
        offset: 50,
        message: '饭菜我煮',
        duration: 5000
      })
      if (this.dianJi === 4) return this.$message({
        type: 'warning',
        center: true,
        offset: 50,
        message: '工资全上交',
        duration: 5000
      })
      if (this.dianJi === 5) return this.$message({
        type: 'warning',
        center: true,
        offset: 50,
        message: '不藏私房钱',
        duration: 5000
      })
      if (this.dianJi === 6) return this.$message({
        type: 'warning',
        center: true,
        offset: 50,
        message: '只对你一个人好',
        duration: 5000
      })
      if (this.dianJi === 7) {
        this.dianJi = 0
        return this.$message({
          type: 'warning',
          center: true,
          offset: 50,
          message: '只爱你一个人',
          duration: 5000
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @media (min-width: 0px) and (max-width: 768px) {
    .login_box {
      width: 400px;
      height: 350px;
      background-color: #ffffff;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      /*transform: translate(-50%, -50%);*/
      background-image: url("~@/assets/helihong(58).jpg");
      margin: auto;
      background-position: center center;
      background-repeat: repeat;
      background-size: 100% 100%;
      background-color: rgba(255,255,255,0.5);
    }
  }
  @media (min-width: 768px) and (max-width: 992px){
    .login_box {
      width: 400px;
      height: 350px;
      background-color: #ffffff;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      /*transform: translate(-50%, -50%);*/
      background-image: url("../assets/helihong(58).jpg");
      margin: auto;
      background-position: center center;
      background-repeat: repeat;
      background-size: 100% 100%;
      background-color: rgba(255,255,255,0.5);
    }
  }
  @media (min-width: 992px) {
    .login_box {
      width: 400px;
      height: 350px;
      background-color: #ffffff;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      /*transform: translate(-50%, -50%);*/
      background-image: url("~@/assets/helihong(58).jpg");
      margin: auto;
      background-position: center center;
      background-repeat: repeat;
      background-size: 100% 100%;
      background-color: rgba(255,255,255,0.5);
    }
  }
  @media (min-width: 0px) and (max-width: 768px) {
    .div1 {
      font-size: 17px;
    }
    .button1 {
      font-size: 10px;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .div1 {
      font-size: 25px;
    }
    .button1 {
      font-size: 15px;
    }
  }
  @media (min-width: 992px) {
    .div1 {
      font-size: 25px;
    }
    .button1 {
      font-size: 15px;
    }
  }
  @media (min-width: 0px) and (max-width: 768px) {
    .div2 {
      font-size: 45px;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .div2 {
      font-size: 50px;
    }
  }
  @media (min-width: 992px) {
    .div2 {
      font-size: 65px;
    }
  }

  .login_container {
    background-image: url('~@/assets/helihong(52).jpg');
    background-position: center center;
    background-repeat: repeat;
    background-size: 100% 100%;
    height: 100%;
  }

  /*.login_box {*/
  /*  width: 450px;*/
  /*  height: 300px;*/
  /*  background-color: #ffffff;*/
  /*  border-radius: 5px;*/
  /*  position: absolute;*/
  /*  left: 50%;*/
  /*  top: 50%;*/
  /*  transform: translate(-50%, -50%);*/
  /*  background-image: url("../assets/2.jpg");*/
  /*}*/

  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0 0 10px #dddddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eeeeee;
    }

  }

  .btns {
    display: flex;
    justify-content: flex-end;
  }

  .login_from {

    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .el-dialog div {
    color: #e83e8c;
    font-family: Sanfont;
    /*font-size: 50px;*/
  }

</style>
