<template>
    <div>订单列表</div>
</template>

<script>
export default {
  name: 'Orders'
}
</script>

<style scoped>

</style>
