<template>
  <div class="home-img" >
  <el-container class="home-container">
    <!--   头部区域   -->
    <el-header >
      <div :style="styleNone" @mouseover="editHove"  @mouseleave="mouseleave">
        <img src="../assets/aixin.jpg">
        <span>我想对全世界说：贺丽鸿，我喜欢你</span>
      </div>
      <el-button @click="logout" class="logoutbutton" type="info">注销</el-button>
    </el-header>
    <!--   页面主体区域   -->
    <el-container >
      <!--   侧边栏   -->
      <div :style="styleNone" @mouseover="editHove"  @mouseleave="mouseleave">
        <el-aside :width="iscollapse ? '50px' : '140px'" class="box_imgs">
          <div @click="toggleCollapse" class="toggle-button">|||</div>
          <!--  侧边栏菜单区域      -->
          <el-menu
            :collapse="iscollapse"
            :collapse-transition="false"
            :default-active="activePath"
            :router="true"
            :unique-opened="true"
            active-text-color="#409eff"
            background-color="rgba(255,255,255,0)"
            class="el-menu-vertical-demo"
            text-color="blue"
            disabled>
            <!--  一级菜单   -->
            <el-submenu index="/home">
              <template slot="title">
                <!--  图标   -->
                <i class="el-icon-loading"></i>
                <!--  文本   -->
                <span style="color: yellow" >欢迎贺贺</span>
              </template>
              <el-menu-item @click="saveNavstate('/welcome' )" index="/welcome">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span style="color: yellow">爱情首页</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="/image">
              <template slot="title">
                <!--  图标   -->
                <i class="el-icon-camera-solid"></i>
                <!--  文本   -->
                <span style="color: yellow">照片墙</span>
              </template>
              <el-menu-item @click="saveNavstate('/image' )" index="/image">
                <template slot="title">
                  <i class="el-icon-picture"></i>
                  <span style="color: yellow">照片</span>
                </template>
              </el-menu-item>
              <el-menu-item @click="saveNavstate('/imagewall' )" index="/imagewall">
                <template slot="title">
                  <i class="el-icon-video-camera-solid"></i>
                  <span style="color: yellow">3D照片</span>
                </template>
  <!--              <span slot="title"><a target="_blank"  href="https://helihong.xiedeqingxdq.com/imgwall" style="text-decoration:none;color: #e83e8c">3D照片</a></span>-->
              </el-menu-item>
            </el-submenu>
            <el-menu-item @click="saveNavstate('/aixin' )" index="/aixin">
              <template slot="title">
                <i class="el-icon-star-off"></i>
                <span style="color: yellow">爱心树</span>
              </template>
  <!--            <span slot="title"> <a target="_blank" href="https://helihong.xiedeqingxdq.com/tree" style="text-decoration:none;color: #e83e8c">爱心树</a></span>-->
            </el-menu-item>
            <el-menu-item @click="saveNavstate('/charfirework' )" index="/charfirework">
              <template slot="title">
                <i class="el-icon-star-on"></i>
                <span style="color: yellow">一箭穿心</span>
              </template>
  <!--            <span slot="title"><a target="_blank" href="https://helihong.xiedeqingxdq.com/char" style="text-decoration:none;color: #e83e8c">一箭穿心</a></span>-->
            </el-menu-item>
            <el-menu-item @click="saveNavstate('/firework' )" index="/firework">
              <template slot="title">
                <i class="el-icon-platform-eleme"></i>
                <span style="color: yellow">漫天烟花</span>
              </template>
  <!--            <span slot="title"><a target="_blank"  href="https://helihong.xiedeqingxdq.com/" style="text-decoration:none;color: #e83e8c">漫天烟花</a></span>-->
            </el-menu-item>

          </el-menu>
        </el-aside>
      </div>
      <!--   右侧内容主体  -->
      <el-main :class="isurl">
        <!-- 路由占位符-->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
  </div>

</template>

<script>
export default {
  data () {
    return {
      // 左侧菜单数据
      menuList: [],
      menusList: [{
        "id": 101,
        "authName": "管理",
        "path": "goods",
        "children": [
          {
            "id": 104,
            "authName": "列表",
            "path": "goods",
            "children": [],
            "order": 1
          },
          {
            "id": 115,
            "authName": "参数",
            "path": "params",
            "children": [],
            "order": 2
          },
          {
            "id": 121,
            "authName": "分类",
            "path": "categories",
            "children": [],
            "order": 3
          }
        ],
        "order": 3
      }],
      iconsObj: {
        125: 'el-icon-s-custom',
        103: 'el-icon-user-solid',
        101: 'el-icon-user-solid',
        102: 'el-icon-user-solid',
        145: 'el-icon-user-solid'
      },
      isurl: 'back_img',
      iscollapse: false,
      // 激活的链接地址
      activePath: '',
      isClassMainPath: '',
      //实时屏幕宽度
      windowWidth: document.documentElement.clientWidth,
      styleNone: {display: "block"}

    }
  },
  watch: {
    windowWidth (val) {
      let that = this;
      if (val < 980) {
        this.iscollapse = true
      }else if (val >980){
        this.iscollapse = false
      }
    }
  },
  created () {
    // this.getMenuList()
    this.isClassMain()
  },
  methods: {
    logout () {

      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    // 鼠标划过触发事件
    editHove () {
      if (this.iscollapse === true) {
        this.styleNone.display = "block"
        this.toggleCollapse()
      }
    },
    // 鼠标离开时触发事件
    mouseleave () {
      if (this.iscollapse === false)
      {this.toggleCollapse()}

    },
    // 获取所有有权限的菜单
    async getMenuList () {
      const { data: res } = await this.$http.get('menus')
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.menuList = res.data
      // console.log(this.menuList)
    },
    // 点击按钮切换菜单的折叠与展开
    toggleCollapse () {
      // 取反
      this.iscollapse = !this.iscollapse

    },
    saveNavstate (activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
      this.isClassMain()
      // console.log(this.activePath)
    },
    // 根据url目录判断class样式的增加和删除
    isClassMain () {
      this.activePath = window.sessionStorage.getItem('activePath')
      if (this.activePath) {
        if (this.activePath === '/welcome') {
          this.isurl = 'back_img'
          // console.log('111' + this.activePath)
          return
        } else if (this.activePath !== '/welcome') {
          this.isurl = ''
          // console.log('222' + this.activePath)
          return
        }
      }
      if (!this.activePath) {

      }
    }

  },
  // 浏览器刷新要执行的事件函数
  mounted () {
    window.addEventListener('load', () => {
      // 写入想要刷新页面时执行的代码

    })
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight;  // 高
        that.windowWidth = window.fullWidth; // 宽
      })()
    }
  }
}
</script>

<style lang="less" scoped>
  .home-container {
    height: 100%;

  }
  .box_imgs {
    height: 100%;
    background-image: url("../assets/helihong(6).jpg");
    background-position: center center;
    background-repeat: repeat-y;
    background-size: 100% 100%;
  }
  .home-img {
    height: 100%;
  }

  .el-header {
    background-color: #e83e8c;
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    align-items: center;
    color: navy;
    font-size: 20px;

    > div {
      display: flex;
      align-items: center;

      span {
        margin-left: 15px;
      }
    }
  }

  .el-aside {
    background-color: darkmagenta;

    .el-menu {
      border-right: none;
    }
  }

  .el-main {
    background-color: #EAEDF1;
  }

  .el-submenu [class^=el-icon] {
    margin-right: 1px;
    margin-left: 0px;
  }

  @media (min-width: 0px) and (max-width: 768px) {
    .toggle-button {
      margin-top: 10px;
      background-color: rgba(255, 255, 255, 0);
      font-size: 35px;
      text-align: center;
      line-height: 30px;
      color: #ffffff;
      letter-spacing: 0.3em;
      cursor: pointer;
      display: block;
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .toggle-button {
      margin-top: 5px;
      background-color: rgba(255, 255, 255, 0);
      font-size: 8px;
      text-align: center;
      line-height: 15px;
      color: #ffffff;
      letter-spacing: 0.3em;
      cursor: pointer;
      display: none;
    }
  }
  @media (min-width: 992px) {
    .toggle-button {
      margin-top: 10px;
      background-color: rgba(255, 255, 255, 0);
      font-size: 8px;
      text-align: center;
      line-height: 15px;
      color: #ffffff;
      letter-spacing: 0.3em;
      cursor: pointer;
      display: none;
    }
  }

  .WelcomeHome {
    text-align: center;
  }

  .back_img {
    background-image: url('../assets/helihong(59).jpg');
    background-position: center center;
    background-repeat: repeat;
    background-size: 100% 100%;

  }

  .home {
    width: 100%;
  }
  .logoutbutton {
    position: fixed;
    left: 90%;
  }
  .el-icon-menu {
    color: #3a8ee6;
  }

</style>
