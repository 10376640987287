<template>
    <div>goods商品列表</div>
</template>

<script>
export default {
  name: 'Goods'
}
</script>

<style scoped>

</style>
