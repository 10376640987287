import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Login from '@/components/Login.vue'
import Home from '@/components/Home.vue'
import Welcome from '@/components/Welcome.vue'
import Users from '@/components/user/users.vue'
import Roles from '@/components/role/roles.vue'
import Rights from '@/components/power/Rights.vue'
import Cate from '@/components/goods/Cate.vue'
import Params from '@/components/goods/Params'
import Goods from '@/components/goods/Goods'
import Orders from '@/components/orders/Orders'
import Reports from '@/components/reports/Reports'
// import WelcomeHome from '@/components/WelcomeHome'
import Image from '@/components/image/Image'
import Imagewall from '@/components/image/Imagewall'
import Aixin from '@/components/image/Aixin'
import Charfirework from '@/components/image/Charfirework'
import Firework from '@/components/image/Firework'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: '/login',
    component: Login,
    meta:{
      // 页面标题title
      title: '贺贺，请登录呦'
    }
  },
  {
    path: '/home',
    component: Home,
    // 重定向到welcome页
    redirect: '/welcome',
    meta:{
      // 页面标题title
      title: '贺贺，欢迎来到我们的网站。这是一个有爱的网站'
    },
    // 子路由跳转
    children: [
      {
        path: '/welcome',
        component: Welcome,
        meta:{
          // 页面标题title
          title: '贺贺'
        }
      },
      {
        path: '/users',
        component: Users
      },
      {
        path: '/roles',
        component: Roles
      },
      {
        path: '/rights',
        component: Rights
      },
      {
        path: '/categories',
        component: Cate
      },
      {
        path: '/params',
        component: Params
      },
      {
        path: '/goods',
        component: Goods
      },
      {
        path: '/orders',
        component: Orders
      },
      {
        path: '/reports',
        component: Reports
      },
      {
        path: '/image',
        component: Image
      },
      {
        path: "/imagewall",
        component: Imagewall
      },
      {
        path: "/aixin",
        component: Aixin
      },
      {
        path: "/charfirework",
        component: Charfirework
      },
      {
        path: "/firework",
        component: Firework
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // 自定义网站标题
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  // to 代表要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数表示放行
  // next() 放行       next('/login') 强制跳转
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})

export default router
