<template>
  <div id="iframe1" style=" position: absolute;
width: 100%; height: 100%;margin-top: -20px;margin-left: -20px;">
    <iframe  frameborder="0"  align="middle"  src="https://helihong.xiedeqingxdq.com/char/" width="100%" height="100%" scrolling="yes"  ></iframe>
  </div>
</template>

<script>
  export default {
    data () {
      return {}
    },
    created () {
    },
    mounted () {
    },
    methods: {

    }
  }
</script>

<style lang="less" scoped>
</style>
