<template>
  <div class="addUser">
    <!--  面包屑导航按钮  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  Card 卡片视图区域   -->
    <el-card>
      <!-- 搜索添加区域 -->
      <el-row :gutter="20">
        <el-col :span="10">
          <el-input @clear="getUserList" clearable placeholder="请输入内容" v-model="queryInfo.query">
            <el-button @click="getUserList" icon="el-icon-search" slot="append"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button @click="dialogVisible = true" type="primary">添加用户</el-button>
        </el-col>
      </el-row>
      <!--  用户列表区域  -->
      <el-table

        :data="userlist"
        border
        stripe
        style="width: 100%">
        <el-table-column label="#" type="index">
        </el-table-column>
        <el-table-column
          label="姓名"
          prop="username"
          width="180">
        </el-table-column>
        <el-table-column
          label="邮箱"
          prop="email">
        </el-table-column>
        <el-table-column
          label="电话"
          prop="mobile">
        </el-table-column>
        <el-table-column
          label="角色"
          prop="role_name"
          width="180">
        </el-table-column>
        <el-table-column
          label="状态">
          <template slot-scope="scope">
            <el-switch
              @change="userStateChanged(scope.row)"
              active-color="#13ce66"
              inactive-color="#ff4949"
              v-model="scope.row.mg_state">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="180px">
          <template slot-scope="scope">
            <el-tooltip :enterable="false" class="item" content="修改" effect="dark" placement="top">
              <el-button @click="showEditDialog(scope.row)" icon="el-icon-edit" size="mini" type="primary"></el-button>
            </el-tooltip>
            <el-tooltip :enterable="false" class="item" content="删除" effect="dark" placement="top">
              <el-button @click="removeUser(scope.row.id)" icon="el-icon-delete" size="mini" type="danger"></el-button>
            </el-tooltip>
            <el-tooltip :enterable="false" class="item" content="分配角色" effect="dark" placement="top" >
              <el-button icon="el-icon-setting" size="mini" type="warning"  @click="setRole(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--    分页区域    -->
      <!-- :page-sizes 按每页显示多少条显示数据-->
      <el-pagination
        :current-page="queryInfo.pagenum"
        :page-size="queryInfo.pagesize"
        :page-sizes="[2, 5, 10, 20]"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </el-card>
    <!--添加用户对话框-->
    <el-dialog
      :visible.sync="dialogVisible"
      title="添加用户"
      width="50%"
      @close="addDialogClosed"
    >
      <!-- 内容主体区域-->
      <el-form :model="addForm" :rules="rulesForm" label-width="90px" ref="ruleFormRef">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="addForm.mobile"></el-input>
        </el-form-item>

      </el-form>

      <!--底部信息-->
      <span class="dialog-footer" slot="footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button @click="addUser" type="primary">确 定</el-button>
        </span>
    </el-dialog>
    <!--修改用户的对话框-->
    <el-dialog
      title="修改用户信息"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <el-form :model="editform" :rules="editFormRules" label-width="90px" ref="editFormRef">
        <el-form-item label="用户名">
          <el-input v-model="editform.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="editform.email"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="editform.mobile"></el-input>
        </el-form-item>
      </el-form>
      <!--底部信息-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateUser">确 定</el-button>
      </span>
    </el-dialog>
    <!--分配角色的对话框-->
    <el-dialog
      title="分配角色"
      :visible.sync="setRolesDialogVisible"
      width="50%"
    @close="setRolesDialogClose">
      <div>
        <p>当前的用户：{{userInfos.username}}</p>
        <p>当前的角色：{{userInfos.role_name}}</p>
        <p>
          分配的角色：
          <el-select v-model="selectedRoleId" placeholder="请选择">
            <el-option
              v-for="item in rolesList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id">
            </el-option>
          </el-select>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="setRolesDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="saveRoleInfo">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'addUser',
  data () {
    // 验证邮箱的自定义校验规则
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请正确填写邮箱'))
      } else {
        if (value !== '') {
          const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          if (!reg.test(value)) {
            callback(new Error('请输入有效的邮箱'))
          }
        }
        callback()
      }
    }
    const checkMobile = (rule, value, cb) => {
      // 验证手机号的自定义校验规则
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        return cb()
      } else {
        cb(new Error('请输入合法的手机号'))
      }
    }
    return {
      // http请求传参
      queryInfo: {
        query: '',
        // 当前的页数
        pagenum: 1,
        // 当前每页显示多少条数据
        pagesize: 2
      },
      userlist: [],
      // 总共有多少条数据
      total: 0,
      dialogVisible: false,
      // 控制修改用户对话框的显示与隐藏
      editDialogVisible: false,
      // 添加用户表单
      addForm: {
        username: '',
        password: '',
        email: '',
        mobile: ''
      },
      // 编辑用户数据
      editform: {
        username: '',
        email: '',
        mobile: ''
      },
      editUpdateId: '',
      // 添加用户规则验证
      rulesForm: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        },
        {
          min: 4,
          max: 16,
          message: '长度在 4 到 16 个字符间',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 24,
          message: '长度在 6 到 24 个字符间',
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: '请输入邮箱',
          trigger: 'blur'
        },
        {
          validator: validateEmail,
          trigger: 'blue'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        },
        {
          validator: checkMobile,
          trigger: 'blue'
        }]
      },
      editFormRules: {
        email: [{
          required: true,
          message: '请输入邮箱',
          trigger: 'blur'
        },
        {
          validator: validateEmail,
          trigger: 'blue'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        },
        {
          validator: checkMobile,
          trigger: 'blue'
        }]
      },
      // 控制分配角色的对话框开关
      setRolesDialogVisible: false,
      // 需要被分配角色的用户信息
      userInfos: {},
      // 所有角色的数据列表
      rolesList: [],
      // 已选中的角色id值
      selectedRoleId: ''
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    async getUserList () {
      const { data: res } = await this.$http.get('users', { params: this.queryInfo })
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.userlist = res.data.users
      this.total = res.data.total
      // console.log(res)
    },
    // 监听 pagesize 改变的事件函数
    handleSizeChange (NewSize) {
      // console.log(`每页 ${NewSize} 条`);
      this.queryInfo.pagesize = NewSize
      this.getUserList()
    },
    // 监听 页码值 改变的事件函数
    handleCurrentChange (NewPage) {
      // console.log(`当前页: ${NewPage}`);
      this.queryInfo.pagenum = NewPage
      this.getUserList()
    },
    // 修改按键状态
    async userStateChanged (userInfo) {
      // console.log(userInfo)
      const { data: res } = await this.$http.put(`users/${userInfo.id}/state/${userInfo.mg_state}`)
      if (res.meta.status !== 200) {
        userInfo.mg_state = !userInfo.mg_state
        return this.$message.error(res.meta.msg)
      } else {
        return this.$message.success(res.meta.msg)
      }
    },
    // 添加用户
    addUser () {
      this.$refs.ruleFormRef.validate(async valid => {
        if (!valid) return
        // 通过预校验 ，发起添加用户网络请求
        const { data: res } = await this.$http.post('users', this.addForm)
        if (res.meta.status !== 201) return this.$message.error(res.meta.msg)
        this.dialogVisible = false
        this.getUserList()
      })

      // console.log(res)
    },
    // 监听添加用户对话框的关闭事件 用于清空表内容
    addDialogClosed () {
      this.$refs.ruleFormRef.resetFields()
      this.addForm.username = ''
      this.addForm.email = ''
      this.addForm.mobile = ''
    },
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    removeUser (id) {
      console.log(id)
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.delete(`users/${id}`)
        if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
        this.$message.success(res.meta.msg)
        this.getUserList()
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    // 展示编辑用户的对话框
    showEditDialog (userInfo) {
      // console.log(userInfo)
      this.editDialogVisible = true
      this.editform.username = userInfo.username
      this.editform.email = userInfo.email
      this.editform.mobile = userInfo.mobile
      this.editUpdateId = userInfo.id
    },
    // 修改用户信息并提交
    updateUser () {
      // 表单预验证
      this.$refs.editFormRef.validate(async valid => {
        // console.log(valid)
        if (!valid) return
        // 通过预校验 ，发起添加用户网络请求
        const { data: res } = await this.$http.put(`users/${this.editUpdateId}`, { email: this.editform.email, mobile: this.editform.mobile })
        if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
        // 关闭对话框
        this.editDialogVisible = false
        // 重新获取用户列表
        this.getUserList()
        // 提示修改成功
        this.$message.success(res.meta.msg)
      })
    },
    async setRole (userInfo) {
      this.userInfos = userInfo
      // 在展示对话框前获取所有的角色列表
      const { data: res } = await this.$http.get('roles')
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.rolesList = res.data
      this.setRolesDialogVisible = true
    },
    // 点击确认按钮分配角色
    async saveRoleInfo () {
      if (!this.selectedRoleId) return this.$message.error('请选择要分配的角色')
      const { data: res } = await this.$http.put(`users/${this.userInfos.id}/role`, { rid: this.selectedRoleId })
      if (res.meta.status !== 200) return this.$message.error('更新角色失败  ' + res.meta.msg)
      this.$message.success('更新角色成功', res.meta.msg)
      await this.getUserList()
      this.setRolesDialogVisible = false
    },
    setRolesDialogClose() {
      this.selectedRoleId = ''
      this.userInfos = {}
    }
  }
}
</script>

<style lang="less" scoped>

</style>
