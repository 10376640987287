<template>
  <div style="position: absolute">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>照片墙</el-breadcrumb-item>
      <el-breadcrumb-item>照片</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
        <el-image  v-for="(url, id) in urls" :key="id" :src="url" lazy fit="fit"  :preview-src-list="urls" ></el-image>
    </el-card>
  </div>

</template>


<script>
  export default {
    data () {
      return {
        urls: [
          "https://helihong.xiedeqingxdq.com/img/helihong(1).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(1).png",
          "https://helihong.xiedeqingxdq.com/img/helihong(2).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(2).png",
          "https://helihong.xiedeqingxdq.com/img/helihong(3).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(4).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(5).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(6).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(7).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(8).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(9).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(10).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(11).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(12).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(13).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(14).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(15).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(16).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(17).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(18).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(19).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(20).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(21).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(23).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(24).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(35).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(25).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(36).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(26).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(37).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(27).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(28).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(29).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(30).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(31).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(32).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(33).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(34).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(52).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(53).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(54).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(55).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(56).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(57).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(58).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(59).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(60).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong(61).jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong62.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong63.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong64.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong65.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong66.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong67.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong68.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong69.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong70.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong71.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong72.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong73.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong74.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong75.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong76.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong77.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong78.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong79.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong80.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong81.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong82.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong83.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong84.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong85.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong86.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong87.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong88.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong89.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong90.jpg",
          "https://helihong.xiedeqingxdq.com/img/helihong91.jpg",
        ]
      }
    }
  }
</script>

<style lang="less" scoped>

  @media (min-width: 0px) and (max-width: 768px) {
    .el-image {
      width: 160px;
      height: 160px
    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    .el-image {
      width: 200px;
      height: 200px
    }
  }
  @media (min-width: 992px) {
    .el-image {
      width: 250px;
      height: 250px
    }
  }
</style>
