<template>
  <div id="slider" style="position: absolute">
    <div class="window" @mouseover="stop" @mouseleave="play">
      <ul class="container" :style="containerStyle">
        <li>
          <img :style="{width:imgWidth+'px'}" :src="sliders[sliders.length - 1].img" alt="">
        </li>
        <li  v-for="(item, index) in sliders" :key="index">
          <img :style="{width:imgWidth+'px'}" :src="item.img" alt="">
        </li>
        <li>
          <img :style="{width:imgWidth+'px'}" :src="sliders[0].img" alt="">
        </li>
      </ul>
      <ul class="direction">
        <li class="left" @click="move(1000, 1, speed)">
          <svg class="icon" width="30px" height="30.00px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#ffffff" d="M481.233 904c8.189 0 16.379-3.124 22.628-9.372 12.496-12.497 12.496-32.759 0-45.256L166.488 512l337.373-337.373c12.496-12.497 12.496-32.758 0-45.255-12.498-12.497-32.758-12.497-45.256 0l-360 360c-12.496 12.497-12.496 32.758 0 45.255l360 360c6.249 6.249 14.439 9.373 22.628 9.373z"  /></svg>
        </li>
        <li class="right" @click="move(1000, -1, speed)">
          <svg class="icon" width="30px" height="30.00px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#ffffff" d="M557.179 904c-8.189 0-16.379-3.124-22.628-9.372-12.496-12.497-12.496-32.759 0-45.256L871.924 512 534.551 174.627c-12.496-12.497-12.496-32.758 0-45.255 12.498-12.497 32.758-12.497 45.256 0l360 360c12.496 12.497 12.496 32.758 0 45.255l-360 360c-6.249 6.249-14.439 9.373-22.628 9.373z"  /></svg>
        </li>
      </ul>
      <ul class="dots">
        <li v-for="(dot, i) in sliders" :key="i"
            :class="{dotted: i === (currentIndex-1)}"
            @click = jump(i+1)
        >
        </li>
      </ul>
    </div>
    <div>
      <main class="el-main" data-v-05385aa0="">
        <div class="welcome" data-v-05385aa0="" data-v-38c51b54=""><h2 data-v-38c51b54="">Welcome</h2>
          <h3 data-v-38c51b54="">欢迎你贺丽鸿 & 谢德清的空间</h3>
          <div class="info" data-v-38c51b54=""><h4 data-v-38c51b54="">哈哈哈 :</h4>
            <ul data-v-38c51b54="">
              <li data-v-38c51b54=""><a data-v-38c51b54="" href="https://www.xiedeqingxdq.com" target="_blank">这是属于我们两个人的网站
              </a><span data-v-38c51b54="">>>------------------>>></span> 贺丽鸿: <img alt=""
                                                                                    data-v-38c51b54=""
                                                                                    src="../assets/aixin2.jpg"
                                                                                    style="text-align: center; width: 250px;height: 200px">
              </li>
              <li data-v-38c51b54=""><a data-v-38c51b54="" href="https://helihong.xiedeqingxdq.com/tree"  target="_blank">满颗都是爱你的树</a><span
                data-v-38c51b54="">里面有我想对你说的话 -&gt;  www.xiedeqingxdq.com</span>
              </li><br/>
              <br/>
              <li data-v-38c51b54=""><a data-v-38c51b54="" href="https://helihong.xiedeqingxdq.com/char" target="_blank">烟花</a><span
                data-v-38c51b54=""> 一箭穿心</span></li><br/>
              <br/>
              <li data-v-38c51b54=""><a data-v-38c51b54=""
                                        href="https://helihong.xiedeqingxdq.com/"
                                        target="_blank">漫天烟花</a><span data-v-38c51b54=""> 写满了对你的祝福和喜欢</span></li><br/>

              <li data-v-38c51b54="">
                <hr data-v-38c51b54="">
              </li>
              <li data-v-38c51b54=""><a data-v-38c51b54="" href="https://www.xiedeqingxdq.com/#/image" target="_blank">照片墙</a><span
                data-v-38c51b54=""> 点击可查看关于你的照片</span></li><br/>

            </ul>
          </div>
          <br/>
          <br/>
          <address data-v-38c51b54="">仅属于我们两人的网站</address>
        </div>
      </main>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'slider',
    props: {
      initialSpeed: {
        type: Number,
        default: 30
      },
      initialInterval: {
        type: Number,
        default: 3
      }
    },
    data () {
      return {
        sliders:[
          {
            img:'https://helihong.xiedeqingxdq.com/img/helihong(54)54.jpg'
          },
          {
            img:'https://helihong.xiedeqingxdq.com/img/helihong(52)52.jpg'
          },
          {
            img:'https://helihong.xiedeqingxdq.com/img/helihong(59)59.jpg'
          },
          {
            img:'https://helihong.xiedeqingxdq.com/img/helihong(53)53.jpg'
          },
          {
            img:'https://helihong.xiedeqingxdq.com/img/helihong(62)62.jpg'
          },
        ],
        imgWidth:1000,
        currentIndex:1,
        distance:-1000,
        transitionEnd: true,
        speed: this.initialSpeed
      }
    },
    computed:{
      containerStyle() {
        return {
          transform:`translate3d(${this.distance}px, 0, 0)`
        }
      },
      interval() {
        return this.initialInterval * 1000
      }
    },
    mounted() {
      this.init()
    },
    methods:{
      init() {
        this.play()
        window.onblur = function() { this.stop() }.bind(this)
        window.onfocus = function() { this.play() }.bind(this)
      },
      move(offset, direction, speed) {
        console.log(speed)
        if (!this.transitionEnd) return
        this.transitionEnd = false
        direction === -1 ? this.currentIndex += offset/1000 : this.currentIndex -= offset/1000
        if (this.currentIndex > 5) this.currentIndex = 1
        if (this.currentIndex < 1) this.currentIndex = 5

        const destination = this.distance + offset * direction
        this.animate(destination, direction, speed)
      },
      animate(des, direc, speed) {
        if (this.temp) {
          window.clearInterval(this.temp);
          this.temp = null ;
        }
        this.temp = window.setInterval(() => {
          if ((direc === -1 && des < this.distance) || (direc === 1 && des > this.distance)) {
            this.distance += speed * direc
          } else {
            this.transitionEnd = true
            window.clearInterval(this.temp)
            this.distance = des
            if (des < -3000) this.distance = -1000
            if (des > -1000) this.distance = -3000
          }
        }, 20)
      },
      jump(index) {
        const direction = index - this.currentIndex >= 0 ? -1 : 1;
        const offset = Math.abs(index - this.currentIndex) * 1000;
        const jumpSpeed = Math.abs(index - this.currentIndex) === 0 ? this.speed : Math.abs(index - this.currentIndex) * this.speed ;
        this.move(offset, direction, jumpSpeed)
      },
      play() {
        if (this.timer) {
          window.clearInterval(this.timer)
          this.timer = null
        }
        this.timer = window.setInterval(() => {
          this.move(1000, -1, this.speed)
        }, this.interval)
      },
      stop() {
        window.clearInterval(this.timer)
        this.timer = null
      }
    }
  }

</script>

<style lang="less" scoped>
  @media (min-width: 0px) and (max-width: 768px) {

  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  ol,ul{
    list-style: none;
  }
  #slider{
    text-align: center;
    /*border-radius: 20px;*/
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .window{
    position:relative;
    width:1000px;
    height:700px;
    margin:0 auto;
    overflow:hidden;
  }
  .container{
    display:flex;
    position:absolute;
  }
  .left, .right{
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    width:50px;
    height:50px;
    background-color:rgba(0,0,0,.3);
    border-radius:50%;
    cursor:pointer;
  }
  .left{
    left:1%;
    padding-left:5px;
    padding-top:18px;
    margin-top: 60%;
  }
  .right{
    right:1%;
    padding-right:5px;
    padding-top:18px;
    margin-top: 60%;
  }
  img{
    user-select: none;
  }
  .dots{
    position:absolute;
    bottom:10px;
    left:50%;
    transform:translateX(-50%);
  }
  .dots li{
    display:inline-block;
    width:15px;
    height:15px;
    margin:0 3px;
    border:1px solid white;
    border-radius:50%;
    background-color:#333;
    cursor:pointer;
  }
  .dots .dotted{
    background-color:orange;
  }
  .el-main {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    overflow: auto;
    box-sizing: border-box;
  }
  div {
    display: block;
  }
  .welcome[data-v-38c51b54] {
    font-size: 22px;
    text-align: center;
    color: yellow;

  }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    *{
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
    ol,ul{
      list-style: none;
    }
    #slider{
      text-align: center;
      /*border-radius: 20px;*/
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    .window{
      position:relative;
      width:400px;
      height:500px;
      margin:0 auto;
      overflow:hidden;
    }
    .container{
      display:flex;
      position:absolute;
    }
    .left, .right{
      position:absolute;
      top:50%;
      transform:translateY(-50%);
      width:50px;
      height:50px;
      background-color:rgba(0,0,0,.3);
      border-radius:50%;
      cursor:pointer;
    }
    .left{
      left:1%;
      padding-left:5px;
      padding-top:18px;
      margin-top: 60%;
    }
    .right{
      right:1%;
      padding-right:5px;
      padding-top:18px;
      margin-top: 60%;
    }
    img{
      user-select: none;
    }
    .dots{
      position:absolute;
      bottom:10px;
      left:50%;
      transform:translateX(-50%);
    }
    .dots li{
      display:inline-block;
      width:15px;
      height:15px;
      margin:0 3px;
      border:1px solid white;
      border-radius:50%;
      background-color:#333;
      cursor:pointer;
    }
    .dots .dotted{
      background-color:orange;
    }
    .el-main {
      display: block;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
      overflow: auto;
      box-sizing: border-box;
    }
    div {
      display: block;
    }
    .welcome[data-v-38c51b54] {
      font-size: 22px;
      text-align: center;
      color: yellow;

    }
  }
  @media (min-width: 992px) {
    *{
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
    ol,ul{
      list-style: none;
    }
    #slider{
      text-align: center;
      /*border-radius: 20px;*/
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    .window{
      position:relative;
      width:400px;
      height:500px;
      margin:0 auto;
      overflow:hidden;
    }
    .container{
      display:flex;
      position:absolute;
    }
    .left, .right{
      position:absolute;
      top:50%;
      transform:translateY(-50%);
      width:50px;
      height:50px;
      background-color:rgba(0,0,0,.3);
      border-radius:50%;
      cursor:pointer;
    }
    .left{
      left:1%;
      padding-left:5px;
      padding-top:18px;
      margin-top: 60%;
    }
    .right{
      right:1%;
      padding-right:5px;
      padding-top:18px;
      margin-top: 60%;
    }
    img{
      user-select: none;
    }
    .dots{
      position:absolute;
      bottom:10px;
      left:50%;
      transform:translateX(-50%);
    }
    .dots li{
      display:inline-block;
      width:15px;
      height:15px;
      margin:0 3px;
      border:1px solid white;
      border-radius:50%;
      background-color:#333;
      cursor:pointer;
    }
    .dots .dotted{
      background-color:orange;
    }
    .el-main {
      display: block;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
      overflow: auto;
      box-sizing: border-box;
    }
    div {
      display: block;
    }
    .welcome[data-v-38c51b54] {
      font-size: 22px;
      text-align: center;
      color: yellow;

    }
  }
</style>
